<div class="container">
    <app-loader *ngIf="loading"></app-loader>
   <div class="cityheader">
        <div>
            <h3>Select City</h3>
            <p>Select one city you want to start with</p>
        </div>
        <div>
            <input type="text" class="form-control" placeholder="Search" [(ngModel)]="search_item">
        </div>
   </div>
    <div class="icon-container">
        <ng-container *ngIf="( cityList | searchfilter: 'place_name': search_item) as result">
            <div *ngFor="let cities of result" class="box" (click)="citySelect(cities,'view')" [ngClass]="{active: cities.place_id==selectedCity.place_id}">
                <div id="content-mob">
                    <div style="display: flex;flex-direction: column;justify-content: center;align-items: center; white-space: nowrap;">
                        <img class="countrycons" src="{{cities.image}}" alt="{{cities.place_name}}" onerror="this.src='assets/MIAssets/Country/Others.png'">
                        <span>{{cities.place_name}}</span>
                    </div>
                </div>
            </div>
            <div style="padding: 5px 20px; text-align: center; width: 100%;" *ngIf="result.length === 0">
                <img src="assets/MIAssets/no_data_icon.svg" alt="no-results">
                <h4 class="mt-3">{{'No Record Found'}}</h4>
            </div>
        </ng-container>
    </div>
      
</div>