import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
    private api: ApiService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    //this.checkResourceAccess('attributegroups','GET',false);
  }

  // return index of oblect from object array, return number
  getArrayindex(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj, k) => {
        if (obj[key] == val && flag) {
          res = k;
          flag = false;
        }
      });
    }
    return res;
  }
  // move array element by changing it's index
  array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
      var k = new_index - arr.length + 1;
      while (k--) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr; // for testing
  }
  // extract value by key from object array, return object
  getArrayValue(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    arrayObj.forEach((obj) => {
      if (obj[key] == val && flag) {
        res = obj;
        flag = false;
      }
    });
    return res;
  }
  modifyInt(options: any) {
    const { value, roundUp, convertToLocaleString, floatingPoint } = options;
    let initialValue = value;
    if (isPlatformBrowser(this.platformId)) {
      if (value === 0) initialValue = 0;
      if (roundUp === true) initialValue = Math.ceil(+initialValue);
      if (roundUp === false) initialValue = Math.round(+initialValue);
      if (floatingPoint > 0) initialValue = initialValue.toFixed(floatingPoint);
      if (convertToLocaleString === true || convertToLocaleString === undefined)
        initialValue = Number(initialValue).toLocaleString();
    }

    return initialValue;
  }
  removeDuplicateKey(arr: Array<any>) {
    //get unique array
    let uniqueArray = [];
    arr.forEach(function (element) {
      if (uniqueArray.indexOf(element) === -1) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }
  // remove duplicate object key - val
  uniqueObject(key: any, arr: Array<Object>) {
    //get unique array
    let _this = this;
    let uniqueArray = [];
    arr.forEach(function (element: Object) {
      if (_this.getArrayindex(key, element[key], uniqueArray) == null) {
        uniqueArray.push(element);
      }
    });
    return uniqueArray;
  }

  getArrayValueNull(key: any, val: any, arrayObj: any): any {
    let res = null;
    let flag = true;
    if (arrayObj) {
      arrayObj.forEach((obj) => {
        if (obj[key] == val && flag) {
          res = obj;
          flag = false;
        }
      });
    }
    return res;
  }

  // get array of child element from parent
  getChildArray(key: any, val: any, arrayObj: any): any {
    let res: any = [];
    let i = 0;
    arrayObj.forEach((obj) => {
      if (obj[key] == val) {
        res[i] = obj;
        i++;
      }
    });
    return res;
  }

  // remove particular value from array of object
  getDeletedArray(key: any, val: any, arrayObj: any): any {
    if (Array.isArray(val)) {
      // accepting both array and single value
      val = val;
    } else {
      val = [val];
    }
    let res: any = [];
    let i = 0;
    if (arrayObj) {
      arrayObj.forEach((obj) => {
        if (val.indexOf(obj[key]) == -1) {
          res[i] = obj;
          i++;
        }
      });
    }
    return res;
  }

  jsonLength(obj) {
    return Object.keys(obj).length;
  }
  //accepting only date Object
  formatDate(value: any) {
    value = new Date(value);
    return (
      value.getFullYear() +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getDate().toString().padStart(2, 0)
    );
  }
  formatDateUser(value: any) {
    value = new Date(value);
    return (
      value.getDate().toString().padStart(2, 0) +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getFullYear()
    );
  }
  formatDateUTC(dt: any, getTime: boolean = false) {
    let value;
    dt = new Date(dt);
    value = new Date(dt + ' UTC');
    if (getTime) {
      return  value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'}) + " " + value.getHours().toString().padStart(2, 0) + ":"
        + value.getMinutes().toString().padStart(2, 0) + ":" + value.getSeconds().toString().padStart(2, 0);
    } else {
      return (
        value.toLocaleDateString('en-GB', {day: 'numeric',month: 'short',year: 'numeric'})
      );
    }
  }

  
  /**
   * This is a function generate a list of years from 2009 to current year

   * @returns {void} Returns void.
   */
  getYears() {
    let years = [];
    const year = new Date().getFullYear();
    years.push(year);
    const back_year = year - 2008;
    for (var i = 1; i < back_year; i++) {
      years.push(year - i);
    }    
    return years;
  }

  getAddressText(address_obj: any) {
    let address = '';
    if (address_obj['neigh_name'])
      address += '' + address_obj.neigh_name + ', ';
    if (address_obj['block_name'])
      address += 'Block ' + address_obj.block_name + ', ';
    if (address_obj['street_name'])
      address += '' + address_obj.street_name + ', ';
    if (address_obj['lane_name']) address += '' + address_obj.lane_name + ', ';
    if (address_obj['parcel_no'])
      address += 'Parcel ' + address_obj.parcel_no + ', ';
    if (address_obj['house_no'])
      address += 'House No ' + address_obj.house_no + ', ';
    if (address_obj['building_name'])
      address += ' ' + address_obj.building_name;
    if (address) {
      return address;
    } else {
      return 'Invalid address';
    }
  }

  // copy json object without reference
  deepCopyJson(obj) {
    let copy = obj,
      k;

    if (obj && typeof obj === 'object') {
      copy = Object.prototype.toString.call(obj) === '[object Array]' ? [] : {};
      for (k in obj) {
        //copy[k] = this.deepCopyJson(obj[k]);
        copy[k] = obj[k];
      }
    }

    return copy;
  }

  getErrorMessage(err) {
    let msg = err.error;
    return msg.message;
  }

  checkResourceAccess(name, flag = true) {
    let resource = {
      GET: false,
      PATCH: false,
      POST: false,
      DELETE: false,
    };
    if (flag) {
      //this.api.getAllowResources(); //call the api again
    }
    let resources = this.api.allowResources;
    if (resources && resources.length) {
      resources.forEach((element) => {
        if (element.resource_name == name && element.methods) {
          if (element.methods.indexOf('GET') != -1) {
            resource.GET = true;
          }
          if (element.methods.indexOf('POST') != -1) {
            resource.POST = true;
          }
          if (element.methods.indexOf('PATCH') != -1) {
            resource.PATCH = true;
          }
          if (element.methods.indexOf('DELETE') != -1) {
            resource.DELETE = true;
          }
        }
      });
    }
    return resource;
  }

  assignmentSortOrder(data) {
    var newdata = [];
    let label = '';
    data.forEach((element) => {
      if (element.assignment_label[this.api.language] == null) {
        label = element.assignment_name;
      } else {
        label = element.assignment_label[this.api.language];
      }
      element.name = label;
      newdata.push(element);
    });
    newdata.sort(this.GetSortOrder('name'));
    return newdata;
  }

  //Comparer Function
  GetSortOrder(prop) {
    return function (a, b) {
      if (a[prop].toLowerCase() > b[prop].toLowerCase()) {
        return 1;
      } else if (a[prop].toLowerCase() < b[prop].toLowerCase()) {
        return -1;
      }
      return 0;
    };
  }

  datecompare(a,b,isAsc: boolean){
    a = new Date(a.start_date).getTime();
    b=  new Date(b.start_date).getTime()
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  removeUnderscores(value) {
    if (value) {
      value = value.toString().trim();
      value = value.toLowerCase();
      let name = value.replace(/_/g, ' ');
      return this.camelizeAll(name);
    } else {
      return '';
    }
  }
  camelizeAll(str) {
    const arr = str.split(' ');
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(' ');
    return str2;
  }
  
  formatInternationNum(num) {
    let ans = '';
         if(isNaN(num))
           return "Invalid Input"
         if(num >= 1000 && num <= 999999) {
           ans = (num / 1000).toFixed(2)+' K'
         }else if(num >= 1000000 && num <= 999999999) {
           ans = (num / 1000000).toFixed(2)+' M';
         }else if(num >= 1000000000 && num <= 999999999999) {
           ans = (num / 1000000000).toFixed(2)+' B'
         }else {
           ans = num + ""
         }
         return ans;
       }

}
